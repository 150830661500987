table tr, table td {
    box-shadow: none;
}
main > header.mb-3 {
    margin-bottom: 0 !important;
}
#root > main > div > div > table.table-striped > tbody > tr {
    background: #fff;
    --bs-table-striped-bg: #fff;
}
#root > main > div > div > table.table-striped > tbody > tr > td {
    padding: .5rem .8rem;
    vertical-align: middle;
    cursor: pointer;
}
#root > main > div > div > table.table-striped > tbody > tr:hover {
    background: #edf1ff;
}
#root > main > div > div > table.table-striped > tbody > tr:hover > td {
    box-shadow: none;
}
.pagination button {
    box-shadow: none;
    border: 1px solid #dee2e6;
    border-radius: 0;
}
.pagination button:hover {
    background: #ff954f;
    border: 1px solid #ff954f;
    color: #fff;
}
.pagination button.active {
    background: #4f69ff;
    border: 1px solid #4f69ff;
    color: #fff;
}
.pagination button:first-of-type {
    box-shadow: none;
    border: 1px solid #dee2e6;
    border-radius: 6px 0 0 6px;
}
.pagination button:last-of-type {
    box-shadow: none;
    border: 1px solid #dee2e6;
    border-radius: 0 6px 6px 0;
}
.table-striped tbody tr:nth-of-type(odd) {
    background: #fff3de;
}
.table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-accent-bg: none;
}
#root > main > div > div.mt-5.container > div.justify-content-md-left.w-100.row > div > table > tbody > tr > td {
    box-shadow: none;
}
#root > main > div > div.mt-5.container > table > tbody tr td:nth-of-type(4n) > * {
    display: flex;
    width: max-content;
    padding: 10px;
    border-radius: 15px;
    margin: auto 0;
    vertical-align: middle;
}