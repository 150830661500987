.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial,Helvetica,sans-serif;
  line-height: 1.125em
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: .75em;
  font-weight: 700
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px
}

.react-calendar__tile--now {
  background: #ffff76
}

.react-calendar__tile--now:enabled:hover,.react-calendar__tile--now:enabled:focus {
  background: #ffffa9
}

.react-date-picker {
  display: inline-flex;
  position: relative
}

.react-date-picker,.react-date-picker *,.react-date-picker *:before,.react-date-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

.react-date-picker--disabled {
  background-color: #f0f0f0;
  color: #6d6d6d
}

.react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: thin solid gray
}

.react-date-picker__inputGroup {
  min-width: calc((4px * 3) + .54em * 8 + .217em * 2);
  flex-grow: 1;
  padding: 0 2px;
  box-sizing: content-box
}

.react-date-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre
}

.react-date-picker__inputGroup__input {
  min-width: .54em;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  -moz-appearance: textfield
}

.react-date-picker__inputGroup__input::-webkit-outer-spin-button,.react-date-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0
}

.react-date-picker__inputGroup__input:invalid {
  background: rgba(255,0,0,.1)
}

.react-date-picker__inputGroup__input--hasLeadingZero {
  margin-left: -.54em;
  padding-left: calc(1px + .54em)
}

.react-date-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px
}

.react-date-picker__button:enabled {
  cursor: pointer
}

.react-date-picker__button:enabled:hover .react-date-picker__button__icon,.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  stroke: #0078d7
}

.react-date-picker__button:disabled .react-date-picker__button__icon {
  stroke: #6d6d6d
}

.react-date-picker__button svg {
  display: inherit
}

.react-date-picker__calendar {
  width: 350px;
  max-width: 100vw;
  z-index: 10
}

.react-date-picker__calendar--closed {
  display: none
}

.react-date-picker__calendar .react-calendar {
  border-width: thin
}

.react-calendar {
  border-radius: var(--rounded-corners);
  width: 350px;
  max-width: 100%;
  background: white;
  font: inherit;
  line-height: 1.125em;
  border: 0;
  overflow: hidden
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
  font: inherit;
  /* font-size: .8em */
}

.react-calendar button:enabled:hover {
  cursor: pointer
}

.react-calendar--doubleView {
  width: 700px
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -.5em
}

.react-calendar--doubleView .react-calendar__viewContainer>* {
  width: 50%;
  margin: .5em
}

.react-calendar,.react-calendar *,.react-calendar *:before,.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 10px
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none
}

.react-calendar__navigation button:enabled:hover,.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6
}

.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: .75em
}

.react-calendar__month-view__weekdays__weekday {
  padding: .5em
}

.react-calendar__month-view__weekNumbers {
  font-weight: 700
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: .75em;
  padding: 1em calc(.5em / .75)
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575
}

.react-calendar__year-view .react-calendar__tile,.react-calendar__decade-view .react-calendar__tile,.react-calendar__century-view .react-calendar__tile {
  padding: 2em .5em
}

.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: .75em .5em;
  background: none
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0
}

.react-calendar__tile:enabled:hover,.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6
}

.react-calendar__tile--hasActive {
  background: #76baff
}

.react-calendar__tile--hasActive:enabled:hover,.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff
}

.react-calendar__tile--active {
  background: #006edc;
  color: #fff
}

.react-calendar__tile--active:enabled:hover,.react-calendar__tile--active:enabled:focus {
  background: #1087ff
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6
}

.react-picker * {
  font-family: inherit
}

.react-picker[class*=-picker--closed],.react-picker[class*=-picker--closed] [class*=-picker__wrapper] {
  border-radius: var(--rounded-corners)
}

.react-picker[class*=-picker--open],.react-picker[class*=-picker--open] div[class*=-picker__wrapper] {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0
}

div[class*=-picker__wrapper] {
  background: white;
  border: 0;
  border-radius: 4px;
  font-size: 1.25em
}

div[class*=-picker__inputGroup] {
  min-width: calc(4px + (4px * 3) + .54em * 8 + .217em * 2);
  flex-grow: 1;
  display: flex;
  padding: 2px;
  align-items: baseline
}

span[class*=-picker__inputGroup__divider] {
  padding: 1px
}

input[class*=-picker__inputGroup__input] {
  -moz-appearance: textfield
}

input[class*=-picker__inputGroup__input]::-webkit-outer-spin-button,input[class*=-picker__inputGroup__input]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0
}

input[class*=-picker__inputGroup__input]:invalid {
  background: rgba(255,0,0,.1)
}

input[class*=-picker__inputGroup__input--hasLeadingZero] {
  margin-left: -.54em;
  padding-left: calc(1px + .54em)
}

div[class*=-picker__calendar] {
  box-shadow: var(--shadow);
  border-radius: var(--rounded-corners);
  border-top-left-radius: 0
}

div[class*=-picker__calendar] .react-calendar {
  border-top-left-radius: 0
}

div[class*=-picker__clock] {
  box-shadow: var(--shadow);
  border-radius: var(--rounded-corners);
  font-family: inherit;
  width: 220px;
  height: 220px;
  padding: 20px;
  border: 0;
  border-top-left-radius: 0
}

div[class*=-picker__clock] .react-clock__second-hand {
  transition: transform cubic-bezier(.68,0,.27,1.55) .2s
}

div[class*=-picker__clock] .react-clock__second-hand__body:before {
  width: 5px;
  height: 5px
}

div[class*=-picker__clock] .react-clock__second-hand__body:after {
  width: 15px;
  height: 15px
}

:root {
  --white: #ecf0f2;
  --blueish-white: #f8fafc;
  --gray: #dee4f0;
  --orange: #e74c3c;
  --purple: #78378c;
  --blue: #3c91e7;
  --shadow: 0 30px 40px 0 rgba(16, 36, 94, .2);
  --rounded-corners: 4px;
  --rounded-corners-large: 8px
}

body {
  margin: 0;
  background: var(--bkg);
  font-family: Montserrat,Segoe UI,Tahoma,sans-serif
}

body.theme-blue {
  --bkg: var(--blueish-white);
  --primary: var(--blue);
  --secondary: var(--purple)
}

body.theme-orange {
  --bkg: var(--blueish-white);
  --primary: var(--orange);
  --secondary: var(--purple)
}

*,*:before,*:after {
  box-sizing: border-box
}

h1,h2,h3,h4,h5,h6 {
  font-weight: 600;
  text-transform: uppercase
}

h2 {
  margin-bottom: .325em;
  font-size: 2.8em;
  word-wrap: break-word;
  hyphens: auto
}

p {
  margin: 0;
  font-size: 19px
}

a {
  color: inherit;
  text-decoration: underline;
  text-decoration-style: dashed;
  text-underline-offset: .15em
}

a:hover,a:focus {
  outline: none;
  color: inherit;
  text-decoration-style: solid
}

button {
  font-family: inherit
}

.illustration>.react-date-picker {
  box-shadow: 4px 4px #0003
}

.wrapper {
  width: 90%;
  max-width: 1110px;
  margin: 0 auto
}

._main_14uqh_4 {
  margin-top: 4em
}

._section_14uqh_8 {
  padding: 4em 0
}

._section_14uqh_8:nth-of-type(odd) {
  background: var(--gray)
}

._section_14uqh_8:nth-of-type(odd) ._text_14uqh_16 {
  order: 1
}

._section_14uqh_8:nth-of-type(odd) ._illustration_14uqh_20 {
  order: 2
}

@media screen and (min-width: 780px) {
  ._section_14uqh_8:nth-of-type(even) ._illustration_14uqh_20 {
      order:1
  }

  ._section_14uqh_8:nth-of-type(even) ._text_14uqh_16 {
      order: 2
  }
}

@media screen and (min-width: 780px) {
  ._section_14uqh_8 ._illustration_14uqh_20 {
      flex-basis:300px
  }
}

._title_14uqh_40 {
  margin-top: 0
}

._wrapper_14uqh_44 {
  width: 90%;
  max-width: 1110px;
  margin: 0 auto
}

._wrapper_14uqh_44:after {
  content: "";
  display: block;
  clear: both
}

._richContent_14uqh_56 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -1em
}

._richContent_14uqh_56>* {
  margin: 1em;
  max-width: 100%
}

._text_14uqh_16 {
  flex-basis: 400px;
  flex-grow: 1
}

._illustration_14uqh_20 {
  flex-basis: 90%;
  display: flex;
  justify-content: center;
  overflow: hidden
}

._illustration_14uqh_20 img {
  width: 200px;
  height: 200px
}

._container_7lb67_1 {
  padding: .5em 0;
  line-height: 30px
}

._wrapper_7lb67_6 {
  display: flex;
  flex-wrap: wrap;
  align-items: center
}

._title_7lb67_12 {
  margin: .5em 1em .5em 0
}

._others_7lb67_17 select {
  width: 270px;
  height: 40px;
  display: inline-block;
  background: none;
  border: 0;
  border-radius: 25px;
  background-color: #0000001a;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="32" height="24" viewBox="0 0 32 24"><polygon points="0,0 32,0 16,24" fill="rgba%280, 0, 0, .5%29" /></svg>');
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: 244px 15px;
  padding: .4em .7em;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font: inherit
}

._others_7lb67_17 select:hover {
  background-color: #0003
}

._others_7lb67_17 select:focus {
  outline: none;
  border: 2px solid var(--primary);
  padding: calc(.4em - 2px) calc(.7em - 2px);
  background-position: 242px 13px
}

._others_7lb67_17 select option {
  background: var(--white)
}

._header_2716f_1 {
  display: flex;
  background: linear-gradient(130deg,var(--primary) 0,var(--secondary) 100%);
  overflow: hidden;
  font-weight: 600;
  color: #fff;
  padding-top: 5em;
  padding-bottom: calc(5em + (var(--component-height) / 2) - 1em)
}

._main_2716f_11 {
  margin-bottom: .325em;
  font-size: calc(40px + 2.8vw);
  word-wrap: break-word;
  hyphens: auto
}

@media screen and (min-width: 1200px) {
  ._main_2716f_11 {
      font-size:4.6em
  }
}

._sub_2716f_24 {
  margin-bottom: 1.3em;
  font-size: calc(20px + 1.1vw)
}

@media screen and (min-width: 1200px) {
  ._sub_2716f_24 {
      font-size:2.3em
  }
}

._button_2716f_35 {
  display: inline-block;
  background: none;
  border: 0;
  border-radius: 25px;
  background: rgba(255,255,255,.25);
  padding: .4em .7em;
  margin-right: 1em;
  margin-bottom: 1em;
  cursor: pointer;
  font: inherit;
  text-decoration: none;
  text-transform: none;
  color: inherit;
  font-size: 1.5em
}

._button_2716f_35:hover {
  background: var(--white);
  color: var(--primary)
}

._button_2716f_35:focus {
  outline: none;
  border: 2px solid var(--white);
  padding: calc(.4em - 2px) calc(.7em - 2px)
}

._iframeContainer_2716f_63 {
  display: inline-block;
  width: 150px;
  height: 40px;
  transform: scale(1.33);
  transform-origin: top left
}

._iframeContainer_2716f_63 iframe {
  transform-origin: top left;
  transform: scale(1.4)
}

._wrapper_11qyc_1 {
  display: block;
  border-radius: 2px;
  margin: 1em 0;
  padding: 1em;
  background-color: #0000001a;
  position: relative
}

._code_11qyc_10 {
  padding: 0
}

._wrapper_11qyc_1:hover ._button_11qyc_14 {
  opacity: 1
}

._button_11qyc_14 {
  width: 36px;
  height: 36px;
  position: absolute;
  top: calc(50% - 18px);
  right: .4em;
  border: 0;
  border-radius: 50%;
  background: none;
  line-height: .8;
  cursor: pointer
}

._button_11qyc_14:hover {
  background-color: #00000080;
  color: #fff
}

._button_11qyc_14:focus {
  width: 40px;
  height: 40px;
  outline: none;
  border: 2px solid var(--white);
  top: calc(50% - 20px);
  right: calc(.4em - 2px)
}

._button_11qyc_14:hover,._button_11qyc_14:focus {
  opacity: 1
}

._container_hmdp9_1 {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center
}

._background_hmdp9_12 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000080;
  backdrop-filter: blur(5px)
}

._wrapper_hmdp9_22 {
  box-shadow: var(--shadow);
  border-radius: var(--rounded-corners-large);
  position: relative;
  max-height: calc(100% - 10vw);
  min-width: 288px;
  border: 0;
  background: white;
  padding: 44px;
  margin: 5vw;
  overflow: auto;
  font: inherit;
  color: inherit
}

._header_hmdp9_37 {
  display: flex;
  margin-bottom: 2em
}

._header_hmdp9_37 h2 {
  margin-top: 0;
  margin-right: 1em
}

._close_hmdp9_47 {
  flex-shrink: 0;
  width: 44px;
  height: 44px;
  margin-top: -.4em;
  margin-left: auto;
  margin-right: -.4em;
  border: 0;
  border-radius: 50%;
  background: none;
  line-height: .8;
  cursor: pointer
}

._close_hmdp9_47:hover {
  background-color: #00000080;
  color: #fff
}

._close_hmdp9_47:focus {
  width: 48px;
  height: 48px;
  outline: none;
  border: 2px solid var(--white);
  margin-top: calc(-.4em - 2px);
  margin-right: calc(-.4em - 2px)
}

._container_hmdp9_1 h2 {
  margin-bottom: .325em;
  font-size: 1.8em
}

._container_hmdp9_1 h3 {
  margin-bottom: .325em;
  font-size: 1.2em
}

._container_1ijpe_1 {
  width: 90%;
  max-width: 1110px;
  margin-top: calc(var(--component-height) / -2);
  margin-bottom: 4em;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  font-size: 19px
}

._container_1ijpe_1>* {
  box-shadow: var(--shadow)
}

._container_lzz1y_1 {
  padding: 4em 0
}

._title_lzz1y_5 {
  font-size: 2em;
  margin-top: 0;
  margin-bottom: 1em
}

._text_lzz1y_11 {
  margin-top: 1.5em
}

._list_lzz1y_15 {
  margin: -1em;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center
}

._listItem_lzz1y_24 {
  height: 40px;
  max-width: calc(100% - 2em);
  margin: 1em
}

._listItem_lzz1y_24 img {
  max-width: 100%;
  max-height: 100%;
  position: relative;
  width: auto;
  filter: contrast(0%) opacity(50%);
  transition: filter ease-in-out .4s
}

._listItem_lzz1y_24 img:hover {
  filter: none
}

._listItem_lzz1y_24._anz_lzz1y_43 img {
  top: -6px
}

._UsedBy__companies_lzz1y_47 li._ocado_lzz1y_47 img {
  top: -2px
}

._UsedBy__companies_lzz1y_47 li._pb35_lzz1y_51 {
  height: 110px
}

._footer_2hoi0_1 {
  display: flex;
  background: linear-gradient(130deg,var(--primary) 0,var(--secondary) 100%);
  overflow: hidden;
  font-weight: 600;
  color: #fff;
  margin-top: 60px;
  padding-top: 5em;
  padding-bottom: 5em
}

._main_2hoi0_12 {
  margin-bottom: .325em;
  font-size: calc(20px + 1.4vw);
  text-transform: uppercase
}

@media screen and (min-width: 1200px) {
  ._main_2hoi0_12 {
      font-size:2.3em
  }
}

._sub_2hoi0_24 {
  line-height: 2em;
  font-size: calc(10px + .7vw)
}

@media screen and (min-width: 1200px) {
  ._sub_2hoi0_24 {
      font-size:1.15em
  }
}

._button_2hoi0_35 {
  display: inline-block;
  background: none;
  border: 0;
  border-radius: 25px;
  background: rgba(255,255,255,.25);
  padding: .4em .7em;
  margin-right: 1em;
  margin-top: 1em;
  cursor: pointer;
  font: inherit;
  text-decoration: none;
  text-transform: none;
  color: inherit;
  font-size: 1em
}

._button_2hoi0_35:hover {
  background: var(--white);
  color: var(--primary)
}

._button_2hoi0_35:focus {
  outline: none;
  border: 2px solid var(--white);
  padding: calc(.4em - 2px) calc(.7em - 2px)
}
