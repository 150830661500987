#mobile-menu {
    transition-duration: 0.4s;
    z-index: 100 !important;
}
#mobile-menu #links ul li {
    display: block;
    width: max-content;
    color: #c0c0c0;
    padding: 8px;
    font-size: 1rem;
    cursor: pointer;
}
#mobile-menu #links ul a {
    text-decoration: none;
}
#mobile-menu #links ul li:hover {
    color: #fff;
}
nav {
    display: flex;
}

@media all and (min-width:1185px){
    #mobile-menu, #mobile-menu-container {
        display: none !important;
    }
}
@media all and (min-width:570px){
    .container {
        max-width: 100% !important;
    }
}
@media all and (max-width:1184px){
    header {
        border-bottom: 1px solid #ebebeb;
    }
    #menu-btn {
        display: block !important;
    }
    nav {
        display: none !important;
    }
    #root > main > section > section > div:nth-child(4) > div > div.d-flex.flex-row-reverse {
        flex-direction: column !important;
    }
    #issue-status {
        width: 100% !important;
        margin: 0 auto 2rem auto !important;
    }
    #root > main > section > section > div:nth-child(4) > div > div.d-flex.issue-box.me-3 {
        margin: 0 1rem 3rem 1rem !important;
    }
    #root > main > section > section > div:nth-child(4) > div > div.d-flex.issue-box.me-3 > div.d-block.my-auto.ms-auto.me-0 > button {
        position: absolute !important;
        right: 0;
        bottom: -6.2rem;
    }
}