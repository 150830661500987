#sidebar-container {
    display: flex;
    min-width: 300px;
    max-width: 300px;
    position: relative;
    z-index: 10;
}
#sidebar * {
    font-family: Montserrat,Segoe UI,Tahoma,sans-serif;
}
#sidebar {
    background: #f3fdff;
    min-width: 300px;
    max-width: 300px;
    height: 100%;
    /* box-shadow: inset -1px 0px 4px 0px #00000026; */
    border: 1px solid #e9e9e9;
    font-size: 14px;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 10;
}
#sidebar h1 {
    font-size: 1.2rem;
}
#sidebar .label {
    color: #a1a1a1;
    font-size: .9rem;
}
#sidebar > div {
    display: flex;
    margin: 1.2rem auto;
}
#sidebar > div > div {
    display: flex;
    font-weight: 550;
    color: #404040;
}
#sidebar > div > div > span:nth-of-type(even) {
    display: flex;
    background: #e0e0e0;
    width: fit-content;
    padding: 2px 6px;
    margin-left: auto;
    border-radius: 6px;
}
.all_issues#root > main > footer {
    margin-left: 300px !important;
}