/* Import the css file using the below script on the JS file you want to apply it to:

import "./css/styles.css";

*/

.comment {
    display: block;
    position: relative;
    margin-bottom: 30px;
    padding-left: 66px
}

.comment .comment-author-ava {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    border-radius: 50%;
    overflow: hidden
}

.comment .comment-author-ava>img {
    display: block;
    width: 100%
}

.comment .comment-body {
    position: relative;
    padding: 24px;
    border: 1px solid #e1e7ec;
    border-radius: 7px;
    background-color: #fff
}

.comment .comment-body::after,
.comment .comment-body::before {
    position: absolute;
    top: 12px;
    right: 100%;
    width: 0;
    height: 0;
    border: solid transparent;
    content: '';
    pointer-events: none
}

.comment .comment-body::after {
    border-width: 9px;
    border-color: transparent;
    border-right-color: #fff
}

.comment .comment-body::before {
    margin-top: -1px;
    border-width: 10px;
    border-color: transparent;
    border-right-color: #e1e7ec
}

.comment .comment-title {
    margin-bottom: 8px;
    color: #606975;
    font-size: 14px;
    font-weight: 500
}

.comment .comment-text {
    margin-bottom: 12px
}

.comment .comment-footer {
    display: table;
    width: 100%
}

.comment .comment-footer>.column {
    display: table-cell;
    vertical-align: middle
}

.comment .comment-footer>.column:last-child {
    text-align: right
}

.comment .comment-meta {
    color: #9da9b9;
    font-size: 13px
}

.comment .reply-link {
    transition: color .3s;
    color: #606975;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .07em;
    text-transform: uppercase;
    text-decoration: none
}

.comment .reply-link>i {
    display: inline-block;
    margin-top: -3px;
    margin-right: 4px;
    vertical-align: middle 
}

.comment .reply-link:hover {
    color: #0da9ef
}

.comment.comment-reply {
    margin-top: 30px;
    margin-bottom: 0
}
.full-responsive-width {
    width: 100%;
}
.fixed-width {
    width: 1500px;
}

.line {
    width: 100%;
    height: 2px;
    background-color: #ff7401;
}

nav {
    background-color: #404042;
}

@media (max-width: 768px) {
    .navbar {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

.btn-outline-success {

    --bs-btn-color: #f8f9fa;

    --bs-btn-border-color: #ff7401;

    --bs-btn-hover-bg: #ff5e00;

    --bs-btn-hover-border-color: #ff5e00;

}



.btn-primary {

    --bs-btn-bg: #ff7401;

    --bs-btn-border-color: #ff7401;

    --bs-btn-hover-bg:  #ff5e00;

    --bs-btn-active-border-color: #ff5e00;

    --bs-btn-disabled-border-color: #ff5e00;

}

.red-asterisk {
    color: red;
    float: left;
}


.Show-stopper {
    display: block;
}

.label-content{
    display: flex;
}
.red-asterisk{
    float: right;
    padding-left: 2px;
}
.cursor-pointer {
    cursor: pointer;
}

#app > form.issue-form > div:nth-child(2) > label {
    position: relative;
    padding-bottom: 0;
}
#app .form-floating:not(.form-control:disabled)::before {
    background-color: transparent !important;
}
#app > form.cr-form > div.form-floating:nth-of-type(2) > label {
    position: relative;
    padding-bottom: 0;
}
#app > form.cr-form > div.form-floating:nth-of-type(3) > label {
    position: relative;
    padding-bottom: 0;
}
#admin-panel > div > form.banner-form > div.form-floating > label {
    position: relative;
    padding-bottom: 0;
}
#admin-panel > div > form.banner-form > div.form-floating {
    display: flex;
    flex-direction: column-reverse;
}

/* Bootstrap Switch */
.switch {
    position: relative;
    display: inline-block;
    width: 100px;
    height: 34px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(66px);
    -ms-transform: translateX(66px);
    transform: translateX(66px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

/* Bootstrap Switch ends */

#root > main > section > section > div:nth-child(4) > div > div.d-flex.flex-row-reverse > table > thead > tr > th:nth-child(1) {
    width: 110px;
    min-width: 110px;
}
#root > main > section > section > div:nth-child(4) > div > div.d-flex.flex-row-reverse > table > tbody > tr > td pre {
    padding: 10px;
    background: #282828;
    color: #fff;
    border-radius: 4px;
}
.table>:not(caption)>*>* {
    max-width: 300px !important;
}
#issue-status {
    background: #f6f6f6;
    min-width: 310px;
    max-width: 560px;
    height: fit-content;
    min-height: 350px;
    margin-left: 2rem;
    border-radius: 8px;
}
#issue-status td {
    padding: 0.5rem;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    line-height: 1.5;
}
#issue-status td:nth-of-type(2n-1) {
    min-width: 120px;
    display: table-caption;
    /* font-weight: bold; */
}
#issue-status td:nth-of-type(2) {
    min-width: min-content;
}
#issue-status > table > thead {
    padding: 0.5rem;
    border-bottom: 2px solid #eaeaea;
    line-height: 1.5;
}
#issue-status > table > thead > tr > th {
    padding: 0.5rem;
    margin-bottom: 1rem;
}
#issue-status > table {
    width: 100%;
}
#issue-status > table > tbody > tr:nth-child(12) > td > div button, #root > main > section > section > div.m-5.pb-5.h-auto.justify-content-center > div .comment-attachments a > button {
    top: 60%;
    border-radius: 50%;
    padding: 0.8rem;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0.6;
    z-index: 10;
    font-size: 10px;
}
#issue-status > table > tbody > tr:nth-child(12) > td > div , .comment-attachments.mt-4.mb-5 > div > div {
    min-width: 108px;
    min-height: 154px;
    max-width: min-content;
    height: max-content;
}
#issue-status > table > tbody > tr:nth-child(12) > td > div > a , .comment-attachments.mt-4.mb-5 > div > div > a {
    min-width: 80px;
    max-width: 107px;
    min-height: 70px;
    max-height: 120px;
    word-break: break-all;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
}
#issue-status > table > tbody > tr:nth-child(12) > td > div > a > span:nth-of-type(1) {
    display: block;
}
#issue-status > table > tbody > tr:nth-child(12) > td > div:hover > a > span.no-thumbnail , .comment-attachments.mt-4.mb-5 > div > div:hover > a > span.no-thumbnail {
    display: none !important;
}
/* #issue-status > table > tbody > tr:nth-child(12) > td > div:hover > a > span:nth-of-type(2) {
    font-size: 1rem !important;
} */
#issue-status > table > tbody > tr:nth-child(12) > td > div:hover > a > button , .comment-attachments.mt-4.mb-5 > div > div:hover > a > button {
    display: block !important;
}
#root > main > section > section > div.m-5.pb-5.h-auto.justify-content-center > div > div:nth-child(8) > div.d-flex.flex-column.comment-attachments.mt-4.mb-5 > div > div:hover > a button {
    display: block !important;
}
#issue-status > table > tbody > tr:nth-child(12) > td > div button:hover, #root > main > section > section > div.m-5.pb-5.h-auto.justify-content-center > div .comment-attachments a > button:hover {
    opacity: 1;
}
#root > main > section > section > div.m-5.pb-5.h-auto.justify-content-center > div > div:nth-child(8) > div.d-flex.flex-column.comment-attachments.mt-4.mb-5 > div > div:nth-child(1) > a > button {
    opacity: 1;
}
#root > main > section > section > div:nth-child(4) > div > div.d-flex.flex-row-reverse > table > tbody > tr > td {
    border: none !important;
}
#root > main > section > section > div:nth-child(4) > div > div.d-flex.flex-row-reverse {
    border-bottom: 1px solid #dedede;
    padding-bottom: 1rem;
}

#root > main > section > section > div:nth-child(4) > div > div.d-flex.issue-box.me-3 > div.d-block.my-auto.ms-auto.me-0 > #resolve-note {
    background-color: #fa8537;
    position: absolute;
    right: 120px;
}

#app > form.issue-form > div.form-floating {
    display: flex;
    flex-direction: column-reverse;
}
#app > form.cr-form > div.form-floating {
    display: flex;
    flex-direction: column-reverse;
}
.react-date-picker__wrapper {
    padding: 5px;
    border-radius: 8px;
    border: 1px solid #dee2e6 !important;
}

.flex-start {
    align-self: flex-start;
}
.flex-end {
    align-self: flex-end;
}
.relative {
    position: relative;
}
.absolute {
    position: absolute;
}
.fixed {
    position: fixed;
}
.top {
    top: 0;
}
.left {
    left: 0;
}
.absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.strict-absolute-center {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
}
.ws-nowrap {
    white-space: nowrap;
}
.overlayer {
    display: none;
    background: #000000a2;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200;
}
.no-shadow {
    box-shadow: none !important;
}
.no-border {
    border: 0 !important;
}
#resolveNoteModal .modal-body * {
    font-size: 1rem !important;
}
#issue-status > table > tbody > tr:nth-child(12) > td {
    display: table-cell !important;
}

@media all and (max-width: 840px){
    .fixed-width {
        width: 100%;
    }
    #root > main > section > section > div:nth-child(4) {
        margin: 0 auto !important;
    }
    #root > main > section > section > div:nth-child(4) > div > table > thead > tr > th:nth-child(1) {
        min-width: 110px !important;
    }
    #root > main > section > section > div:nth-child(4) > div > table > thead > tr > th:nth-child(3) {
        min-width: 300px !important;
    }
    #root > main > section > section > div.m-5.pb-5.h-auto.justify-content-center {
        margin: 3rem 10px !important;
    }
}