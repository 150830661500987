nav .dropdown {
    max-width: 80px;
    position: relative;
}
nav .dropdown-menu {
    background-color: #3f3e3e;
}
nav .dropdown-item {
    color: #fff;
}
nav .dropdown-item:hover {
    background-color: #525252;
    color: #fff;
}