.knowledge-base-container {
    max-width: 1200px;
    margin: 0 auto;
}

.loading-spinner {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 3px solid #FF8C00;
    border-radius: 50%;
    border-top-color: transparent;
    border-left: transparent;
    animation: spin 1s linear infinite;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
  }
  
  @keyframes spin {
    0% {
      transform: translateY(-50%) rotate(0deg);
    }
    100% {
      transform: translateY(-50%) rotate(360deg);
    }
  }
  
  
  