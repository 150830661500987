#mobile-logo {
    display: none !important;
}
#sidebar #filter {
    display: flex !important;
}
#sidebar #filter tr:not(button) {
    background: #fff0e3 !important;
}

@media all and (max-width:1148px){
    #root > main > div {
        display: flex;
        flex-direction: column !important;
        width: 100% !important;
    }
    #mobile-logo {
        display: flex !important;
    }
    #sidebar > div:nth-child(1) > h1 {
        color: #a1a1a1 !important;
    }
    #sidebar #filter tr:not(button) {
        background: #fff !important;
    }
    #sidebar-container, #sidebar {
        background: #363636;
        display: flex !important;
        flex-direction: column !important;
        width: 100% !important;
        max-width: 100% !important;
        position: relative !important;
        border: 0 !important;
        outline: 0 !important;
        box-shadow: none !important;
    }
    #sidebar > * {
        display: flex !important;
        width: 100% !important;
        padding: 10px 1rem !important;
    }
    #sidebar > div > div {
        color: #9c9c9c !important;
    }
    #sidebar > div > div > span:nth-of-type(even) {
        color: #404040;
    }
    #filter > div > table {
        overflow: hidden;
        border-radius: 5px;
    }
    .all_issues#root > main > footer {
        padding: 10px !important;
        margin: auto 0 !important;
    }
    #root > main > div > div.mt-5.container > table {
        display: block;
        width: 100% !important;
        overflow-x: auto !important;
        scrollbar-width: thin;
        scroll-behavior: smooth !important;
    }
    #root > main > div > div.mt-5.container > table > tbody > tr > td {
        min-width: 200px !important;
    }
    #root > main > div > div.mt-5.container > div.justify-content-md-left.w-100.mx-auto.row > div {
        overflow: auto;
    }
    #root > main > div > div.mt-5.container > div.justify-content-md-left.w-100.mx-auto.row > div > table {
        display: block !important;
        overflow: auto !important;
        min-width: 450px !important;
    }
    #root > main > div > div.mt-5.container > div.justify-content-md-left.w-100.mx-auto.row > div > table > thead, #root > main > div > div.mt-5.container > div.justify-content-md-left.w-100.mx-auto.row > div > table > tbody {
        display: table;
        width: 100%;
    }
}